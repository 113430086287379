import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getExportFileStatus,
  getSelector,
  getSelectedElement,
} from '@/store/selectors/analyticsAlarms'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import { requestGetAllUsers } from '@/store/actions/users/user/getAllUsers'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
import { getUserData } from '@/store/selectors/appSettings'





import { requestActionLogTable } from '@/store/actions/actionLog/getTable'
import { requestActivitiLogFile } from '@/store/actions/actionLog/getFile'
import { setSelectedElement } from '@/store/actions/actionLog'

import AlarmsManager from './component'
import { getGlobalFilters } from '@/store/selectors/appSettings'
import {
  getTenants,
  getSelectedTenant,
  getAdmins,
  getGroups,
  getAllUsers,
} from '@/store/selectors/users'

import { getTableData, getLoading } from  '@/store/selectors/actionLog' 

const mapStateToProps = (state) => ({
  tableData: getTableData(state),
  loading: getLoading(state),
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  selectedElement: getSelectedElement(state),
  globalFilters: getGlobalFilters(state),
  tenants: getTenants(state),
  admins: getAdmins(state),
  groups: getGroups(state),
  users: getAllUsers(state),
  selectedTenant: getSelectedTenant(state),
  loggedInUser: getUserData(state),
})
const mapDispatchToProps = (dispatch) => ({
  requestActionLogTable: (payload) => dispatch(requestActionLogTable(payload)),
  requestActivitiLogFile: (payload) => dispatch(requestActivitiLogFile(payload)),
  requestGetAllTenants: (payload) => dispatch(requestGetAllTenants(payload)),
  requestGetTenantGroups: (payload) => dispatch(requestGetTenantGroups(payload)),
  requestGetAllUsers: (payload) => dispatch(requestGetAllUsers(payload)),
  requestGetAllAdminTenants: (id, role) => dispatch(requestGetAllAdminTenants({ id, role })),
  setSelectedElement: (payload) => dispatch(setSelectedElement(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AlarmsManager))
