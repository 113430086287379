import { connect } from 'react-redux'

import { requestObjectReferenceRealeTable, requestObjectReferenceMoveableTable, successImmoveableTableDictinory, requestImmoveableTableDictinory, setImmoveableTableFilter } from '@/store/actions/objectReference/getTable'
import { requestObjectReferenceFile } from '@/store/actions/objectReference/getFile'

import {
  getDataReale,
  getRealeLoading,
  getInstallationObject,
  getInstallationObjectLoading,
  getImmoveableDictinory,
  getImmoveableTableFilter,
} from '@/store/selectors/objectReferenceReale'
import {
  getDataMoveable,
  getMoveableLoading
} from '@/store/selectors/objectReferenceMoveable'
import {
  setGoToPassportization,
} from '@/store/actions/Ppr'

import { requestInstallationObject } from '@/store/actions/objectReference/getInstallationObject'
import { setSelectedNode } from '@/store/actions/appSettings'



import ObjectReference from './component'

const mapStateToProps = (state) => ({
  dataReale: getDataReale(state),
  dataMoveable: getDataMoveable(state),
  moveableLoading: getMoveableLoading(state),
  realeLoading: getRealeLoading(state),
  installationObject: getInstallationObject(state),
  installationObjectLoading: getInstallationObjectLoading(state),
  immoveableDictinory: getImmoveableDictinory(state),
  immoveableTableFilterValue: getImmoveableTableFilter(state),
})
const mapDispatchToProps = (dispatch) => ({
  requestObjectReferenceRealeTable: (payload) => dispatch(requestObjectReferenceRealeTable(payload)),
  requestObjectReferenceMoveableTable: (payload) => dispatch(requestObjectReferenceMoveableTable(payload)),
  requestObjectReferenceFile: (payload => dispatch(requestObjectReferenceFile(payload))),
  requestInstallationObject: (payload) => dispatch(requestInstallationObject(payload)),
  setGoToPassportization: (payload) => dispatch(setGoToPassportization(payload)),
  setNode: (data, withoutPinned) => dispatch(setSelectedNode(data, withoutPinned)),
  successImmoveableTableDictinory: (payload) => dispatch(successImmoveableTableDictinory(payload)),
  requestImmoveableTableDictinory: (payload) => dispatch(requestImmoveableTableDictinory(payload)),
  setImmoveableTableFilter: (payload) => dispatch(setImmoveableTableFilter(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ObjectReference)
