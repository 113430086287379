import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getObjectReferenceMoveable = createSelector(
  getState,
  (state) => get(state, 'objectReferenceMoveable', {}),
)

export const getDataMoveable = createSelector(
  getObjectReferenceMoveable,
  (settings) => get(settings, 'data'),
)

export const getTableParametersMoveable = createSelector(
  getObjectReferenceMoveable,
  (settings) => get(settings, 'table', {}),
)

export const getMoveableLoading = createSelector(
  getObjectReferenceMoveable,
  (settings) => get(settings, 'loading', false),
)

