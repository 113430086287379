import { all } from 'redux-saga/effects'

import getTableReale from './getTableReale'
import getTableMoveable from './getTableMoveable'
import getFile from './getFile'
import getInstallationObject from './getInstallationObject'
import getObjectPropertyDictinory from './getObjectPropertyDictinory'


export default function* root() {
  yield all([
    getTableReale(),
    getTableMoveable(),
    getFile(),
    getInstallationObject(),
    getObjectPropertyDictinory(),
  ])
}