import { put, takeLatest } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SET_SCHEDULE_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { SET_SCHEDULE, setIsSetScheduleErrorStatus, setOverLapSchedules } from '@/store/actions/objectsGroups'

function* setSchedule({ payload }) {
  const toast = createNotifications()
  try {
    const { groupId, scheduleId, assignedGroupIds } = payload
    const body = {
      groupId,
      scheduleId,
      assignedGroupIds,
    }
    const { data } = yield request({
      url: SET_SCHEDULE_URL,
      method: 'patch',
      body
    })


    if (!data.isAssigned) {
      yield put (setOverLapSchedules(data.overlapSchedules))
      toast({
        title: 'Ошибка операции!',
        description: 'Не удалось установить расписание.',
        type: 'error',
      })
      yield put(setIsSetScheduleErrorStatus(true))
    } else {
      toast({
        title: 'Установка расписания',
        type: 'success',
        description: 'Расписание успешно установлено.',
      })
      yield put(setIsSetScheduleErrorStatus(false))
    }
  } catch (error) {
    // if (error.response.data.errorMessage.startsWith('Schedule.already.assign.to.group')) {
    //   const groupName = error.response.data.errorMessage.split(':').pop().trim()
    //   toast({
    //     title: 'Внимание!',
    //     description: `Нельзя установить данное расписание. Дополните состав группы "${groupName}" новыми объектами, которые должны работать по выбранному расписанию.`,
    //     type: 'error',
    //   })
    //   yield put(setIsSetScheduleErrorStatus(true))
    //   return
    // }
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось установить расписание. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(SET_SCHEDULE.REQUEST, setSchedule)
}
