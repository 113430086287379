import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_PPR_INSTALLATION_OBJECT } from '@/store/actions/Ppr'
import { PPR_INSTALLATION_OBJECT } from '@/constants/apiRoutes'
import {
  errorPprInstallationObject,
  successPprInstallationObject
} from '@/store/actions/Ppr/getInstallationObject'
import createNotifications from '@/helpers/notification'
import { sortObjectIdentifier } from '@/helpers/sortObjectIdentifier'

function* getPPRInstallationObjectSaga ({ payload }) {
  const { parentId, registerNumber, details } = payload

  try {
    const response = yield request({
      url: PPR_INSTALLATION_OBJECT({
        parentId,
        params: {
          registerNumber,
        },
      }),
      method: 'get',
    })
    const formattedData = details.map(item => {
      return {
        ...item,
        opened: false,
        children: response.data.filter(child => child.installationType === item.installationType),
      }
    })
    const sortedData = formattedData.map(item => {
      return {
        ...item,
        children: sortObjectIdentifier(item.children),
    }})
    yield put(successPprInstallationObject(sortedData))
  } catch (error) {
     yield put(errorPprInstallationObject(error))
      console.log('function*getPPRInstallationObjectSaga -> error', error)
      const toast = createNotifications()
      toast({
        title: 'Ошибка операции!',
        description: 'Не удалось получить объекты.\nПовторите попытку позже.',
        type: 'error',
      })
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_PPR_INSTALLATION_OBJECT.REQUEST, getPPRInstallationObjectSaga)
}
