import { GET_OBJECT_REFERENCE_INSTALLATION_OBJECT } from './index'

export const requestInstallationObject = (payload) => ({
  type: GET_OBJECT_REFERENCE_INSTALLATION_OBJECT.REQUEST,
  payload,
})

export const successInstallationObject = (payload) => ({
  type: GET_OBJECT_REFERENCE_INSTALLATION_OBJECT.SUCCESS,
  payload,
})

export const errorInstallationObject = (payload) => ({
  type: GET_OBJECT_REFERENCE_INSTALLATION_OBJECT.ERROR,
  payload,
})
