import styled from 'styled-components'

export default styled.div`
  color: '';
`

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`

export const EmptyLog = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.colors.redisign.lightGray};
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.92rem;
`
