import { GET_ACTION_LOG_TABLE } from './index'

export const requestActionLogTable = (payload) => ({
  type: GET_ACTION_LOG_TABLE.REQUEST,
  payload,
})

export const successActionLogTable = (payload) => ({
  type: GET_ACTION_LOG_TABLE.SUCCESS,
  payload,
})

export const errorActionLogTable = (payload) => ({
  type: GET_ACTION_LOG_TABLE.ERROR,
  payload,
})
