import { keyframes, css } from 'styled-components'
import {
  SKY_SCHEME_NAME,
  SPRING_SCHEME_NAME,
  SUMMER_SCHEME_NAME,
} from '@/constants/names'

export const PICKLED_BLUEWOOD = '#2D364C'
export const CADET_BLUE = '#ADB3C5'
export const EBONY_CLAY = '#232B3E'
export const FUEL_YELLOW = '#EEA322'
export const CERULEAN = '#06AAF3'
export const MALACHITE = '#0EB755'
export const MALACHITE_LIGHT = '#0EB75511'
export const RED = '#FF0000'
export const RED_LIGHT = '#FF000011'
export const ERROR_RED = '#EE2247'
export const MIDDLE_RED = '#F83D14'

export const WHITE = '#FFFFFF'
export const LIGHT_BLACK = '#2F3F4B'
export const GRAY = '#DADADA'
export const BLACK = '#000000'
export const MINE_SHAFT = '#3D3D3D'
export const MISCHKA = '#D0D4E1'
export const SHARK = '#1E2127'
export const SHARK_DARK = '#1B1D24'
export const TUNA = '#30333A'
export const BLACK_HAZE = '#FAFBFB'
export const BLUE_BAYOUX = '#475F7B'
export const SCIENCE_BLUE = '#0376E3'
export const REGENT_GRAY = '#828D99'
export const GHOST = '#BEC2CC'
export const HEARTHER = '#B5C0CD'
export const CATSKILL_WHITE = '#E7EDF3'
export const ATHENS_GRAY = '#E1E5E9'
export const PORCELIAN = '#F0F1F2'
export const AZURE_RADIANCE = '#0093FF'
export const PRIMARY300 = '#1061DF'
export const ATLANTIS = '#8AC135'
export const SUSHI = '#75A22E'
export const ORANGE_PEEL = '#FF9900'
export const TANGERINE = '#E68A00'
export const AMARANTH = '#EE2247'
export const LIGHT_GRAY = '#E5E5E5'
export const DARK_GRAY = '#C5C5C5'

export const SCOOTER = '#35B2DA'
export const CURIUS_BLUE = '#2584DB'
export const ATLANTIS_LIGHT = '#90C737'
export const BLUE_LIGHT = '#5EB8EB'
export const BLUE_DARK = '#0F7CBA'
export const BLUE_ROYAL = '#035AFC'
export const WHITE_GRAY = '#C0C0C0'
export const ORANGE_PEEL_TRANSPERAN = 'rgba(255, 153, 0, 0.5)'
export const AZURE_RADIANCE_TRANSPERANT = 'rgba(0, 147,255, 0.5)'
export const WHITE_TRANSPERANT = 'rgba(255, 255,255, 0.4)'
export const WHITE_TRANSPERANT_4 = 'rgba(255, 255,255, 0.4)'
export const WHITE_TRANSPERANT_2 = 'rgba(255, 255,255, 0.2)'
export const WHITE_TRANSPERANT_5 = 'rgba(255, 255,255, 0.2)'
export const SHARK_TRANSPERANT = 'rgba(30, 33,39, 0.4)'
export const GREY_TRANSPERANT = 'rgba(61, 61, 61, 0.2)'
export const BLACK_TRANSPERANT = 'rgba(0, 0, 0, 0.25)'

export const BLACK_RUSSIAN = '#1E1E24'
export const WHITE_SUN = '#FEFEFE'
export const ALTO = '#DDDDDD'
export const SILVER_SAND = '#BDC3C7'
export const SILVER_DARK = '#F8F8F8'
export const WILD_SAND = '#F4F4F4'
export const BOULDER = '#7C7C7C'
export const RANG_GREY = '#CFD4D9'
export const DOVE_GRAY = '#717171'
export const GRAY_500 = '#9b979b'
export const YELLOW_LIGHT = '#FFC30C'
export const YELLOW_ALARM_WARNING_CONTRAST = '#FFB800'

export const BLACK_HAZE_GRADIENT = 'linear-gradient(180deg, rgba(239,239,239,1) 0%, rgba(255,255,255,1) 100%)'
export const BLACK_HAZE_GRADIENT_LIGHT = 'linear-gradient(180deg, rgba(239, 239, 239, 0.5) 0%, rgba(254, 254, 254, 0.5) 100%)'
export const BLACK_HAZE_GRADIENT_REVERSE = 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(239,239,239,1) 100%)'
export const SELESTIAL_BLUE_GRADIEN = `linear-gradient(180deg, ${SCOOTER} 0%, ${CURIUS_BLUE} 100%)`
export const FIELD_SHADOW = 'inset -3px 2px 8px rgba(189, 195, 199, 0.4)'
export const WHITE_SUN_GRADIENT = `linear-gradient(180deg, #EFEFEF 0%, ${WHITE_SUN} 100%)`

export const BLUE_SCHEME_GRADIENT = 'linear-gradient(180deg, #35B2DA 0%, #2584DB 100%)'
export const GREEN_SCHEME_GRADIENT = 'linear-gradient(180deg, #B2D26E 0%, #74A725 100%)'
export const ORANGE_SCHEME_GRADIENT = 'linear-gradient(180deg, #FBBA58 0%, #FF9A02 100%)'
export const BLUE_SHADOW_SCHEME_GRADIENT = '-1px -1px 2px rgba(255, 255, 255, 0.2), 1px 2px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 2px rgba(255, 255, 255, 0.5)'
export const GREEN_SHADOW_SCHEME_GRADIENT = '-1px -1px 2px rgba(255, 255, 255, 0.2), 1px 2px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 2px rgba(255, 255, 255, 0.5)'
export const ORANGE_SHADOW_SCHEME_GRADIENT = '-1px -1px 2px rgba(255, 255, 255, 0.2), 1px 2px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 2px rgba(255, 255, 255, 0.5)'

export const FUEL_YELLOW_GRADIEN = 'linear-gradient(180deg, #FBBA58 0%, #FF9A02 100%);'
export const PIN_SHADOW = '-1px -1px 2px rgba(255, 255, 255, 0.2), 1px 2px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 2px rgba(255, 255, 255, 0.5)'
export const FORM_INPUT_SHADOW = '-1px -1px 2px rgba(255, 255, 255, 0.2), 1px 2px 4px rgba(0, 0, 0, 0.25), inset 1px 1px 2px rgba(255, 255, 255, 0.5)'
export const GRAPH_SHASOW = '4px 4px 6px rgba(189, 195, 199, 0.5), -4px -4px 6px rgba(255, 255, 255, 0.3)'
export const SELESTIAL_BLUE = '#5EB8EB'
export const SELESTIAL_GRAY = '#DADADA'

// Graph Colors
export const SAFFRON = '#F7D43D'
export const TURMERIC = '#D0B84E'

export const PICTON_BLUE = '#5EB8EB'
export const DANUBE = '#67A6CA'

export const SEA_BUCHTORN = '#FCA026'
export const RAW_SIENNA = '#D5943E'

export const WISTERIA = '#9365B9'
export const TRENDY_PINK = '#8565A0'

export const PHARLAP = '#A48E86'
export const SQUIRREL = '#978882'

export const RIO_GRANDE = '#C4CB01'
export const LEMON_GINGER = '#AEB325'

export const SCHEME_COLORS = {
  [SKY_SCHEME_NAME]: {
    backgrounds: {
      default: SELESTIAL_BLUE,
      defaultGradient: BLUE_SCHEME_GRADIENT,
      fuelYellow: FUEL_YELLOW_GRADIEN,
      hover: SCIENCE_BLUE,
    },
    shadows: {
      default: BLUE_SHADOW_SCHEME_GRADIENT,
    },
  },
  [SPRING_SCHEME_NAME]: {
    backgrounds: {
      default: ATLANTIS_LIGHT,
      defaultGradient: GREEN_SCHEME_GRADIENT,
      fuelYellow: FUEL_YELLOW_GRADIEN,
      hover: SUSHI,
    },
    shadows: {
      default: GREEN_SHADOW_SCHEME_GRADIENT,
    },
  },
  [SUMMER_SCHEME_NAME]: {
    backgrounds: {
      default: ORANGE_PEEL,
      defaultGradient: ORANGE_SCHEME_GRADIENT,
      fuelYellow: FUEL_YELLOW_GRADIEN,
      hover: TANGERINE,
    },
    shadows: {
      default: ORANGE_SHADOW_SCHEME_GRADIENT,
    },
  },
}

export const thin = 100
export const extraLight = 200
export const light = 300
export const regular = 400
export const medium = 500
export const semiBold = 600
export const bold = 700
export const extraBold = 800
export const black = 900

export const fontWeights = {
  thin,
  extraLight,
  light,
  regular,
  medium,
  semiBold,
  bold,
  extraBold,
  black,
}

export const htmlFontSize = 10
export const htmlLargeFontSize = 12
export const fontPTSerif = 'Roboto, sans-serif'

export const fonts = {
  primary: fontPTSerif,
}

export const sizes = {
  headerHeight: 48,
  menuRowHeight: 48,
  sidebarWidthMax: 270,
  sidebarWidthMin: 48,
  sideBarViewTreeMin: 280,
  viewTreeHeightFix: 158,
  tabsNamesHeight: 48,
  unitCardWidth: 230,
  passportizationWidth: 330,
}

export const defaultBoxShadow = (color) => `
  5px 5px 10px  ${color};
`

export const gradients = {
  alarm: 'linear-gradient(98deg, rgba(247,212,61,1) 0%, rgba(231,57,48,1) 100%);',
  normal: 'linear-gradient(31.18deg, #8AC135 0%, #03C4C6 94.43%);',
  light: 'linear-gradient(66.18deg, #717171 -15%, #FFB800 60%);',
}

export const animations = {
  alarm: {
    connect: (time) => `
      background-size: 1000% 100%;
      animation: gradientBG ${time}ms ease-in-out infinite;
    `,
    define: `
      @keyframes gradientBG {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
    `,
  },
  notification: {
    connect: () => `
      transform-origin: top center;
      animation: rotation 3s ease-in-out infinite;
    `,
    define: `
      @keyframes rotation {
        0%,
        60%,
        100% {
          transform: rotate(0deg);
          transform-origin: 50% 0;
        }
        62%,
        72%,
        82% {
          transform: rotate(-22deg);
        }
        67%,
        77%,
        87% {
          transform: rotate(22deg);
        }
        92% {
          transform: rotate(-20deg);
        }
        97% {
          transform: rotate(20deg);
        }
      }
    `,
  },
  slide: {
    connect: (parameters) => `
      ${parameters} cubic-bezier(0.280, -0.325, 0.745, 1.385)
    `,
  },
}

const sizeToLarge = (widthValue) => keyframes`
  0% {
    width: 4px;
  }
  100% {
    width: ${(widthValue ? `${widthValue}%` : '4px')};
  }
`

export const indicatorLineAnimation = ({ widthValue }) => css`
  ${sizeToLarge(widthValue)} 1s ease-in-out 1 forwards;
`

export const DEFAULT_THEME = {
  fonts,
  sizes,
  htmlFontSize,
  htmlLargeFontSize,
  fontWeights,
  defaultBoxShadow,
  gradients,
  animations,
  indicatorLineAnimation,
}
