import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const InvertProjectIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return ( 
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM12 5L9 2L6 5L9 8L12 5ZM12 13L9 10L6 13L9 16L12 13ZM13 6L16 9L13 12L10 9L13 6ZM8 9L5 6L2 9L5 12L8 9Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

InvertProjectIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InvertProjectIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InvertProjectIcon)
