import {
  takeLatest, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'



import { GET_ACTION_LOG_FILE_URL } from '@/constants/apiRoutes'
import { GET_ACTION_LOG_FILE } from '@/store/actions/actionLog'
import { getSelectedElement, getTableParameters } from '@/store/selectors/actionLog'



function* getActionLogFileSaga({payload}) {
  try {
    const {userId, tenantId} = yield select(getSelectedElement)
    const tableParameters = yield select(getTableParameters)
    const response = yield request({
      url: GET_ACTION_LOG_FILE_URL({
        params: {
          userId,
          tenantId,
          excludeColumns: payload.allColumns.filter(x => !tableParameters.activeFields.includes(x)),
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          search: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
        },
      }),
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })  
    
    downloadFileFromBlob({
      blob: response.data,
      fileName: `report.xlsx`,
    })

  } catch (error) {
    console.log(error)
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_ACTION_LOG_FILE.REQUEST, getActionLogFileSaga)
}
