import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'
import get from 'lodash/get'

export const getObjectsGroups = createSelector(
  getState,
  (state) => get(state, 'objectsGroups', {}),
)

export const getTreeData = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'tree', []),
)

export const getSearchTree = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'searchTree', []),
)

export const getTreeStatus = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'treeStatus', []),
)

export const getCreateTreeData = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'createObjectsGroupTree', []),
)

export const getCreateTreeStatus = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'createObjectsGroupTreeStatus', []),
)

export const getObjectsGroup = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'card', {}),
)

export const getObjectsGroupsSelectedNode = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'selectedNode', {}),
)

export const getObjectsGroupsMapObjects = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'mapObjects', []),
)

export const getCreateObjectsGroupsMapObjects = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'createObjectsGroupMapObjects', []),
)

export const getObjectsGroupsMapObjectsStatus = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'mapObjectsStatus', null),
)

export const getCreateObjectsGroupsMapObjectsStatus = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'createObjectsGroupMapObjectsStatus', null),
)

export const getScheduleData = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'scheduleData', {}),
)

export const getScheduleOptions = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'scheduleOptions', []),
)

export const getOverLapSchedules = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'overlapSchedules', []),
)

export const getIsSetScheduleErrorStatus = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'isSetScheduleErrorStatus', null),
)

export const getVerificationOptions = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'verificationOptions', []),
)

export const getVerificationOptionsStatus = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'verificationOptionsStatus', null),
)

export const getVerificationStatus = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'verificationStatus', null),
)

export const getControlData = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'controlData', {}),
)

export const getPassportizationOptions = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'passportizationOptions', []),
)

export const getPassportizationOptionsStatus = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'passportizationOptionsStatus', null),
)

export const getPassportizationTableParameters = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'table', null),
)

export const getPassportizationTableData = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'passportizationTableData', []),
)

export const getPassportizationTableLoading = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'loadingTable', null),
)

export const getPassportizationTableDownloadStatus = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'fileExportStatus', null),
)

export const getPassportizationTableFields = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'tableFields', []),
)

export const getControlCupboardOriginalDictionary = createSelector(
  getObjectsGroups,
  (objectsGroupsState) => get(objectsGroupsState, 'controlCupboardOriginalDictionary', []),
)