import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import qs from 'query-string'
import {
  GET_MOVEABLE_TABLE,
  OBJECT_REFERENCE_MOVEABLE_TABLE
} from '@/store/actions/objectReference'
import { successObjectReferenceMoveableTable, setLoadingTable } from '@/store/actions/objectReference/getTable'
import { GET_MOVEABLE_TABLE_URL } from '@/constants/apiRoutes'
import {
  getTableParametersMoveable,
} from '@/store/selectors/objectReferenceMoveable'
import {
  getImmoveableTableFilter,
} from '@/store/selectors/objectReferenceReale'


function* getMoveableleTableSaga() {
  try {
    yield put(setLoadingTable(true))
    const {search} = window.location
    const projectId = qs.parse(search)['projectId']
    const filterValue = yield select(getImmoveableTableFilter)
    const tableParameters = yield select(getTableParametersMoveable)
    const response = yield request({
      url: GET_MOVEABLE_TABLE_URL({
        params: {
          parentId: projectId,
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          searchText: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          names: filterValue['movableProperty'] && filterValue['movableProperty']['name'].map((item) => item.value),
          years: filterValue['movableProperty'] && filterValue['movableProperty']['year'].map((item) => item.value)
        },
      }),
      method: 'get',
    })

    if (response.data) {
      const {
        content,
        pageable,
      } = response.data
        yield put(successObjectReferenceMoveableTable({
        data: content,
        totalElements: pageable.totalElements,
        totalPages: pageable.totalPages,
      }))
    }
    yield put(setLoadingTable(false))

  } catch (error) {
    yield put(setLoadingTable(false))

  }

  return null
}

export default function* root() {
  yield takeLatest(GET_MOVEABLE_TABLE.REQUEST, getMoveableleTableSaga)
  yield takeLatest(OBJECT_REFERENCE_MOVEABLE_TABLE.SORT, getMoveableleTableSaga)
  yield takeLatest(OBJECT_REFERENCE_MOVEABLE_TABLE.PER_PAGE, getMoveableleTableSaga)
  yield takeLatest(OBJECT_REFERENCE_MOVEABLE_TABLE.PAGE, getMoveableleTableSaga)
  yield takeLatest(OBJECT_REFERENCE_MOVEABLE_TABLE.REQUEST_QUERY, getMoveableleTableSaga)
}
