import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import moment from 'moment'


import { GET_ACTION_LOG_TABLE_URL } from '@/constants/apiRoutes'
import { GET_ACTION_LOG_TABLE , ACTION_LOG_TABLE} from '@/store/actions/actionLog'
import { successActionLogTable, errorActionLogTable } from '@/store/actions/actionLog/getTable'
import { getTableParameters, getSelectedElement } from '@/store/selectors/actionLog'



function* getActionLogTableSaga({payload}) {
  try {
    const tableParameters = yield select(getTableParameters)
    const {userId, tenantId} = yield select(getSelectedElement)

    const response = yield request({
      url: GET_ACTION_LOG_TABLE_URL({
        params: {
          userIds: [userId],
          tenantId,
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          searchText: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
        },
      }),
      method: 'get',
    })

    if (response.data) {
      const {
        content,
        pageable,
      } = response.data
        yield put(successActionLogTable({
          data: content.map(item => {
            return {
              ...item,
              eventTime: moment(item.eventTime).format('DD.MM.YYYY HH:mm')
            }
          }),
        totalElements: pageable.totalElements,
        totalPages: pageable.totalPages,
      }))
    } else {
      yield put(successActionLogTable({}))
    }

  } catch (error) {
    errorActionLogTable()
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_ACTION_LOG_TABLE.REQUEST, getActionLogTableSaga)
  yield takeLatest(ACTION_LOG_TABLE.PAGE, getActionLogTableSaga)
  yield takeLatest(ACTION_LOG_TABLE.SORT, getActionLogTableSaga)
  yield takeLatest(ACTION_LOG_TABLE.PER_PAGE, getActionLogTableSaga)
  yield takeLatest(ACTION_LOG_TABLE.REQUEST_QUERY, getActionLogTableSaga)
}
