import { handleActions } from 'redux-actions'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
  GET_REALE_TABLE,
  OBJECT_REFERENCE_REALE_TABLE,
  SET_LOADING_TABLE,
  GET_OBJECT_REFERENCE_INSTALLATION_OBJECT,
  GET_IMMOVEABLE_DICTINORY,
  SET_IMMOVEABLE_FILTER
} from '@/store/actions/objectReference'
import { fieldsRealProperty } from '@/constants/tablesConfig/objectReference'

const initialState = {
  data: [],
  table: generateDefaultTableState(fieldsRealProperty),
  loading: false,
  installationObject: [],
  installationObjectLoading: false,
  dictionaryTable: {},
  immovebleFilterValue: {},
}

export const reducer = handleActions(
  {
    [GET_REALE_TABLE.REQUEST]: (state) => ({
      ...state,
    }),
    [GET_REALE_TABLE.SUCCESS]: (state, { payload: { data, totalElements } }) => ({
      ...state,
      data,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_REALE_TABLE.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_LOADING_TABLE]: (state, { payload }) => ({
      ...state,
      loading: payload,
    }),
    [GET_OBJECT_REFERENCE_INSTALLATION_OBJECT.REQUEST]: (state) => ({
      ...state,
      installationObjectLoading: true,
    }),
    [GET_OBJECT_REFERENCE_INSTALLATION_OBJECT.SUCCESS]: (state, { payload }) => ({
      ...state,
      installationObject: payload,
      installationObjectLoading: false,
    }),
    [GET_OBJECT_REFERENCE_INSTALLATION_OBJECT.ERROR]: (state) => ({
      ...state,
      installationObjectLoading: false,
    }),
    [GET_IMMOVEABLE_DICTINORY.SUCCESS]: (state, { payload }) => ({
      ...state,
      dictionaryTable: payload,
    }),
    [SET_IMMOVEABLE_FILTER]: (state, { payload }) => ({
      ...state,
      immovebleFilterValue: payload,
    }),
    ...generateDefaultTableReducer(OBJECT_REFERENCE_REALE_TABLE),
  },
  initialState,
)

export default reducer
