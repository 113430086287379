export const ELECTRIC_METER = 'ELECTRIC_METER'
export const MULTIRATE_METER = 'MULTIRATE_METER'
export const CONTROLLER = 'CONTROLLER'
export const CONTACTOR = 'CONTACTOR'
export const BRANCH_CIRCUIT = 'BRANCH_CIRCUIT'
export const LAMP = 'LAMP'
export const PHASE = 'PHASE'
export const LIGHT_SENSOR = 'LIGHT_SENSOR'
export const PHOTO_SENSOR = 'PHOTO_SENSOR'
export const VOLUME_SENSOR = 'VOLUME_SENSOR'
export const FIRE_SENSOR = 'FIRE_SENSOR'
export const TRANSFER_SWITCH = 'TRANSFER_SWITCH'
export const DMX_DEVICE = 'DMX_DEVICE'
export const CONTROLLER_FUSE = 'CONTROLLER_FUSE'
export const FUSE = 'FUSE'
export const CHECKPOINT = 'CHECKPOINT'
export const CURRENT_SENSOR = 'CURRENT_SENSOR'
export const LIGHT_POINT = 'LIGHT_POINT'

export const LIGHT_FIXTURE = 'LIGHT_FIXTURE'
export const LIGHT_FIXTURE_GALEON_S = 'LIGHT_FIXTURE_GALEON_S'
export const LIGHT_FIXTURE_GALEON_L = 'LIGHT_FIXTURE_GALEON_L'
export const LIGHT_FIXTURE_GALAD = 'LIGHT_FIXTURE_GALAD'
export const LIGHT_FIXTURE_GALEON_S_LED = 'LIGHT_FIXTURE_GALEON_S_LED'
export const SENSOR = 'SENSOR'
export const LAMP_MODULE = 'LAMP_MODULE'
export const MODULE = 'MODULE'

// production only SLS-1911
export const UTILITY_POLE_CONCRETE = 'UTILITY_POLE_CONCRETE'
export const UTILITY_POLE_METAL = 'UTILITY_POLE_METAL'
export const UTILITY_POLE_WOOD = 'UTILITY_POLE_WOOD'
export const LIGHT_FIXTURE_CONSOLE_LED = 'LIGHT_FIXTURE_CONSOLE_LED'
export const LIGHT_FIXTURE_CONSOLE_PARK_DNAT = 'LIGHT_FIXTURE_CONSOLE_PARK_DNAT'
export const LIGHT_FIXTURE_CONSOLE_ZKU = 'LIGHT_FIXTURE_CONSOLE_ZKU'
export const LIGHT_FIXTURE_FLOOR_LED = 'LIGHT_FIXTURE_FLOOR_LED'
export const LIGHT_FIXTURE_FLOOR_ZKU = 'LIGHT_FIXTURE_FLOOR_ZKU'
export const LIGHT_FIXTURE_SPOTLIGHT_LED = 'LIGHT_FIXTURE_SPOTLIGHT_LED'
export const LIGHT_FIXTURE_SUSPENDED_LED = 'LIGHT_FIXTURE_SUSPENDED_LED'
export const LIGHT_FIXTURE_SUSPENDED_ZKU = 'LIGHT_FIXTURE_SUSPENDED_ZKU'
export const LIGHT_FIXTURE_WALL_LED = 'LIGHT_FIXTURE_WALL_LED'
export const LIGHT_FIXTURE_WALL_ZKU = 'LIGHT_FIXTURE_WALL_ZKU'

export const GROUP = 'GROUP'
export const PHASE_CIRCUIT_BREAKER = 'PHASE_CIRCUIT_BREAKER'
export const CIRCUIT_BREAKER = 'CIRCUIT_BREAKER'

export const SHUO = 'SHUO'
export const CONTROL_CUPBOARD = 'CONTROL_CUPBOARD'
export const MULTI_RATE_METER = 'MULTI_RATE_METER'
export const METEO = 'METEO'
export const ROAD_TEMPERATURE_SENSOR = 'ROAD_TEMPERATURE_SENSOR'
export const DOOR_OPEN_SENSOR = 'DOOR_OPEN_SENSOR'
export const SMOKE_SENSOR = 'SMOKE_SENSOR'
export const BASE_STATION = 'BASE_STATION'
export const LIGHT_FIXTURE_GROUP = 'LIGHT_FIXTURE_GROUP'
export const UTILITY_POLE = 'UTILITY_POLE'
export const LIGHT_MODULE = 'LIGHT_MODULE'
export const LINE = 'LINE'

export const COMPANY_OBJECT = 'COMPANY_OBJECT'
export const DEVICE = 'DEVICE'
export const WATHER_SUPPLY = 'Узел учета ХВС'
export const CENTRAL_HEATING = 'Узел учета ЦО'
export const CENTRAL_HEATING_DEVICE = 'CENTRAL_HEATING_DEVICE'
export const COLD_WATER_DEVICE = 'COLD_WATER_DEVICE'
export const HEATING_DEVICE = 'HEATING_DEVICE'
export const GROUP_OBJECT = 'GROUP_OBJECT'

export const SWITCHING_GROUP = 'SWITCHING_GROUP'
export const STREETLIGHT_CONTROL_CABINET = 'STREETLIGHT_CONTROL_CABINET'
export const STREETLIGHT_GROUP = 'STREETLIGHT_GROUP'
export const STREETLIGHT = 'STREETLIGHT'
export const WASTE_CONTAINER = 'WASTE_CONTAINER'
export const TRASH = 'TRASH'

export const OUTDOOR_LIGHTING = 'Наружное освещение'
export const WATER_SUPPLY = 'Водоснабжение'
export const HEAT_SUPPLY = 'Теплоснабжение'
export const GARBAGE_COLLECTION = 'Раздельный сбор мусора'
export const CHARGING_STATIONS = 'Зарядные автостанции'
export const HEAT_AND_WATTER_SUPPLY = 'Теплоснабжение и Водоснабжение'

export const BRIZ = 'BRIZ'

export const BRIZ_ELECTRIC_METER = 'ELECTRIC_METER'
export const BRIZ_CONTROLLER = 'CONTROLLER'
export const BRIZ_CONTACTOR = 'CONTACTOR'
export const BRIZ_BRANCH_CIRCUIT = 'BRANCH_CIRCUIT'
export const BRIZ_LAMP = 'LAMP'
export const BRIZ_PHASE = 'PHASE'
export const BRIZ_LIGHT_SENSOR = 'LIGHT_SENSOR'
export const BRIZ_PHOTO_SENSOR = 'PHOTO_SENSOR'
export const BRIZ_VOLUME_SENSOR = 'VOLUME_SENSOR'
export const BRIZ_FIRE_SENSOR = 'FIRE_SENSOR'
export const BRIZ_TRANSFER_SWITCH = 'TRANSFER_SWITCH'
export const BRIZ_DMX_DEVICE = 'DMX_DEVICE'
export const BRIZ_CONTROLLER_FUSE = 'CONTROLLER_FUSE'
export const BRIZ_FUSE = 'FUSE'
export const BRIZ_CHECKPOINT = 'CHECKPOINT'
export const BRIZ_CURRENT_SENSOR = 'CURRENT_SENSOR'
export const BRIZ_PHASE_CIRCUIT_BREAKER = 'PHASE_CIRCUIT_BREAKER'

export const KULON = 'KULON'

export const KULON_SHUO = 'SHUO'
export const KULON_CONTROL_CUPBOARD = 'CONTROL_CUPBOARD'
export const KULON_MULTI_RATE_METER = 'MULTI_RATE_METER'
export const KULON_METEO = 'METEO'
export const KULON_ROAD_TEMPERATURE_SENSOR = 'ROAD_TEMPERATURE_SENSOR'
export const KULON_DOOR_OPEN_SENSOR = 'DOOR_OPEN_SENSOR'
export const KULON_CONTACTOR = 'CONTACTOR'
export const KULON_SMOKE_SENSOR = 'SMOKE_SENSOR'
export const KULON_BASE_STATION = 'BASE_STATION'
export const KULON_LIGHT_FIXTURE_GROUP = 'LIGHT_FIXTURE_GROUP'
export const KULON_UTILITY_POLE = 'UTILITY_POLE'
export const KULON_LIGHT_MODULE = 'LIGHT_MODULE'
export const KULON_LINE = 'LINE'

export const UNILIGHT = 'UNILIGHT'

export const UNILIGHT_SHUO = 'SHUO'
export const UNILIGHT_CONTROL_CUPBOARD = 'CONTROL_CUPBOARD'
export const UNILIGHT_BASE_STATION = 'BASE_STATION'
export const UNILIGHT_LIGHT_FIXTURE_GROUP = 'LIGHT_FIXTURE_GROUP'
export const UNILIGHT_UTILITY_POLE = 'UTILITY_POLE'
export const UNILIGHT_LIGHT_MODULE = 'LIGHT_MODULE'
export const UNILIGHT_LINE = 'LINE'

export const ONE_SIM = 'ONE_SIM'

export const ONE_SIM_COMPANY_OBJECT = 'COMPANY_OBJECT'
export const ONE_SIM_DEVICE = 'DEVICE'
export const ONE_SIM_WATHER_SUPPLY = 'WATHER_SUPPLY'
export const ONE_SIM_CENTRAL_HEATING = 'CENTRAL_HEATING'
// export const ONE_SIM_WATHER_SUPPLY = 'Узел учета ХВС'
// export const ONE_SIM_CENTRAL_HEATING = 'Узел учета ЦО'

export const MESH = 'MESH'

export const MESH_SWITCHING_GROUP = 'SWITCHING_GROUP'
export const MESH_STREETLIGHT_CONTROL_CABINET = 'STREETLIGHT_CONTROL_CABINET'
export const MESH_STREETLIGHT_GROUP = 'STREETLIGHT_GROUP'
export const MESH_STREETLIGHT = 'STREETLIGHT'
export const MESH_AMBIENT_LIGHT_SENSOR = 'AMBIENT_LIGHT_SENSOR'
export const MESH_WASTE_CONTAINER = 'WASTE_CONTAINER'

export const WITHOUT_ASU = 'WITHOUT_ASU'

export const WITHOUT_ASU_LIGHT_FIXTURE = 'LIGHT_FIXTURE'
export const WITHOUT_ASU_SENSOR = 'SENSOR'
export const WITHOUT_ASU_CONTROLLER = 'CONTROLLER'
export const WITHOUT_ASU_ELECTRIC_METER = 'ELECTRIC_METER'

export const PASSPORT_BLOCKS = [
  'LOCATION',
  'OBJECT_CHARACTERISTIC',
  'SURVEY_DATA',
  'SERVICING',
  'GENERAL_INFORMATION',
  'TECHNICAL_CONSTRAINTS',
  'EXPLOITATION',
  'ATTACHMENT',
  'MANAGEMENT_ORGANIZATION_SYSTEM'
]

export const OBJECTS = 'OBJECTS'
export const CONNECTED_LINES = 'CONNECTED_LINES'
export const RUSSIA_BORDERS = 'RUSSIA_BORDERS'

export const CONNECTED_LINES_OBJECT_TYPES = [
  LINE,
  UTILITY_POLE,
  BASE_STATION,
  CONTROL_CUPBOARD
]
