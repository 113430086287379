import styled from 'styled-components'


export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`


export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  position: relative;
`
export const TabHeader = styled.div`
  display: flex;
  height: 48px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const ContainerTable = styled.div`
  width: 100%;
  height: calc(100% - 48px);
`

export const TabButton = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  padding: 5px 10px;
  ${({ active, theme }) => active && `
    border-bottom: 2px solid ${theme.colors.borders.bluePrimary300};
  `}
  min-width: 103px;
  ${({ disabled }) => disabled && `
    pointer-events: none;
    opacity: 0.7;
  `}
`
export const Text = styled.div`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.colors.dim};
  ${({ active, theme }) => active && `
    color: ${theme.colors.colors.bluePrimary300};
  `}
`

export const PageSidebarStyled = styled.div`
  min-width: ${({ theme }) => theme.sizes.sideBarViewTreeMin}px;
  min-width: 49px;
  width: 49px;
  flex: 0 0 auto;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;

  @media (max-width: 910px) {
    width: 100vw;
    position: absolute;
    top: 320px;
    height: calc(100vh - 396px);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px
    z-index: 200;
  }
`

export const ContainerIcon = styled.div`
    background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
    cursor: pointer;
`

export const ContainerTree = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  position: absolute;
  right: 0;
  top: 0;
  width: 340px;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
  z-index: 10;
`
export const HeaderTree = styled.div`
  padding: 15px;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`
export const IconContainer = styled.div`
  svg {
    width: 16px;
    height: 16px;
  }
`
export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
`
export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
`
export const TypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
`

export const ObjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
`

export const TypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`

export const ObjectContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  padding: 5px 0 5px 10px;
  &:hover {
    background: #DDDDDD;
    border-color: transparent;
  }
`

export const TypeTitle = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  &:hover {
    color: #3E3C3E99;
  }
`

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ToggleContainer = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px;
  &:hover {
    background: #DDDDDD;
  }
  svg {
    transform-origin: center;
    transform: rotate(180deg);
  }
  ${({ isOpen }) => isOpen && `
    svg {
      transform-origin: center;
      transform: rotate(0deg);
    }
  `}
`

export const ChildrenCount = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  background: #838D99;
  border-radius: 4px;
  padding: 5px;
  color: #FAFBFB;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
`