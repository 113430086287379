import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {successImmoveableTableDictinory } from '@/store/actions/objectReference/getTable'
import {
  GET_IMMOVEABLE_DICTINORY,
} from '@/store/actions/objectReference'
import { GET_IMMOVEABLE_DICTINORY_URL, GET_MOVEABLE_DICTINORY_URL } from '@/constants/apiRoutes'
import {
  getImmoveableTableFilter,
} from '@/store/selectors/objectReferenceReale'



function* getObjectPropertyDictinorySaga({payload}) {
  try {
    const filterValue = yield select(getImmoveableTableFilter)
    const url = payload === 'realProperty' ? GET_IMMOVEABLE_DICTINORY_URL : GET_MOVEABLE_DICTINORY_URL
    const dictionaryData = yield request({
      url: url({
        params: {
          names: filterValue[payload] && filterValue[payload]['name'].map((item) => item.value),
          years: filterValue[payload] && filterValue[payload]['year'].map((item) => item.value)
        }
      }),
      method: 'get',
    })

    const dictionaryName = dictionaryData.data['NAME'].map(item => {
      return {
          title: item.value,
          value: item.value,
      }
    })

    const dictionaryYear = dictionaryData.data['YEAR'].map(item => {
      return {
          title: item.value,
          value: item.value,
      }
    })
    
    yield put(successImmoveableTableDictinory({name: dictionaryName, year: dictionaryYear}))

  } catch (error) {
    console.log(error)
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_IMMOVEABLE_DICTINORY.REQUEST, getObjectPropertyDictinorySaga)

}
