import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_OBJECTS_GROUPS_TREE = createAction('@/GET_OBJECTS_GROUPS_TREE')
export const GET_OBJECTS_GROUPS_MAP_OBJECTS = createAction('@/GET_OBJECTS_GROUPS_MAP_OBJECTS')
export const SET_OBJECTS_GROUPS_TREE_NODE = '@/OBJECTS_GROUPS_SET_OBJECTS_GROUPS_TREE_NODE'

export const GET_CREATE_OBJECTS_GROUP_TREE = createAction('@/OBJECTS_GROUPS_PAGE_GET_CREATE_OBJECTS_GROUP_TREE')
export const GET_CREATE_OBJECTS_GROUP_SEARCH_TREE = createAction('@/OBJECTS_GROUPS_PAGE_GET_CREATE_OBJECTS_GROUP_SEARCH_TREE')
export const GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS = createAction('@/OBJECTS_GROUPS_PAGE_GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS')
export const GET_OBJECTS_GROUP = createAction('@/OBJECTS_GROUPS_PAGE_GET_OBJECTS_GROUP')
export const CREATE_OBJECTS_GROUP = createAction('@/OBJECTS_GROUPS_PAGE_CREATE_OBJECTS_GROUP')
export const EDIT_OBJECTS_GROUP = createAction('@/OBJECTS_GROUPS_PAGE_EDIT_OBJECTS_GROUP')
export const DELETE_OBJECTS_GROUP = createAction('@/OBJECTS_GROUPS_PAGE_DELETE_OBJECTS_GROUP')

export const GET_SCHEDULE_OPTIONS = createAction('@/OBJECTS_GROUPS_PAGE_GET_SCHEDULE_OPTIONS')
export const GET_SCHEDULE_DATA = createAction('@/OBJECTS_GROUPS_PAGE_GET_SCHEDULE_DATA')
export const SET_SCHEDULE = createAction('@/OBJECTS_GROUPS_PAGE_SET_SCHEDULE')
export const SET_SCHEDULE_ERROR_STATUS = createAction('@/OBJECTS_GROUPS_PAGE_SET_SCHEDULE_ERROR_STATUS')
export const RESET_SCHEDULE = createAction('@/OBJECTS_GROUPS_PAGE_RESET_SCHEDULE')

export const GET_VERIFICATION_OPTIONS = createAction('@/OBJECTS_GROUPS_PAGE_GET_VERIFICATION_OPTIONS')
export const SET_VERIFICATION_STATUS = createAction('@/OBJECTS_GROUPS_PAGE_SET_VERIFICATION_STATUS')
export const VERIFICATION_ALL = createAction('@/OBJECTS_GROUPS_PAGE_VERIFICATION_ALL')

export const GET_CONTROL_DATA = createAction('@/OBJECTS_GROUPS_PAGE_GET_CONTROL_DATA')
export const SET_CONTROL_DATA = createAction('@/OBJECTS_GROUPS_PAGE_SET_CONTROL_DATA')

export const SET_PASSPORTIZATION_DATA = createAction('@/OBJECTS_GROUPS_PAGE_SET_PASSPORTIZATION_DATA')
export const GET_PASSPORTIZATION_OPTIONS = createAction('@/OBJECTS_GROUPS_PAGE_GET_PASSPORTIZATION_OPTIONS')
export const GET_PASSPORTIZATION_TABLE = createAction('@/OBJECTS_GROUPS_PAGE_GET_PASSPORTIZATION_TABLE')
export const GET_PASSPORTIZATION_TABLE_FILE = createAction('@/OBJECTS_GROUPS_PAGE_GET_PASSPORTIZATION_TABLE_FILE')
export const PASSPORTIZATION_TABLE = createTableActions('@/OBJECTS_GROUPS_PAGE_PASSPORTIZATION_TABLE')
export const SET_PASSPORTIZATION_TABLE_FILTER_VALUES = '@/SET_PASSPORTIZATION_TABLE_FILTER_VALUES'

export const SET_LOADING = '@/SET_LOADING'
export const SET_CONTROL_CUPBOARD_ORIGINAL_DICTIONARY = '@/SET_CONTROL_CUPBOARD_ORIGINAL_DICTIONARY'

export const OVER_LAP_SCHEDULES = ('@/OVER_LAP_SCHEDULES')


export const setObjectsGroupsTreeNode = (payload) => ({
  type: SET_OBJECTS_GROUPS_TREE_NODE,
  payload,
})

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
})

export const setControlCupboardOriginalDictionary = (payload) => ({
  type: SET_CONTROL_CUPBOARD_ORIGINAL_DICTIONARY,
  payload,
})

export const setIsSetScheduleErrorStatus = (payload) => ({
  type: SET_SCHEDULE_ERROR_STATUS,
  payload,
})

export const setOverLapSchedules = (payload) => ({
  type: OVER_LAP_SCHEDULES,
  payload,
})
