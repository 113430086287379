import styled from 'styled-components'
import Button from '@/components/blocks/Button'
import LabelWithIcon from '@/components/blocks/LabelWithIcon/styles'

export const FieldsContainer = styled.div` 
  width: 100%;
  padding: 10px;
  ${LabelWithIcon}:first-child {
    margin: 5px 0;
  }
  ${LabelWithIcon} {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 500;
    margin: 15px 0 5px 0;
  }
  ${({ ghostMode }) => ghostMode && `
    opacity: 0.3;
    cursor: not-allowed;
    position: relative;
    top: 0;
    left: 0;
    ::after {
      position: absolute;
      width: 100%;
      height: calc(100vh - 96px);
      content: ' ';
      top: 0;
      left: 0;
    }
  `}
`

export const MessageWrapper = styled.div` 
  width: 100%;
  padding: 10px;
`

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 4px;
  background: #F9EAD5;
  color: #E5910C;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  text-align: left;
  svg {
    min-width: 12px;
    margin: -1px 5px 0 0;
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;
  padding: 7px;
  margin-top: 15px;
  color: ${({ theme }) => theme.colors.colors.light};
  background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  text-transform: capitalize;
  :hover {
    background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  }
`

export const CancelButton = styled(Button)`
  width: 100%;
  padding: 7px;
  margin-top: 15px;
  color: ${({ theme }) => theme.colors.colors.light};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.error};
  text-transform: initial;
  :hover {
    background: ${({ theme }) => theme.colors.backgrounds.redisign.error};
  }
`