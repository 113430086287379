import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  GEOZONE,
  PROJECT,
  ROOT,
  INTEGRATION,
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import MapsIcon from '@/components/icons/maps'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'
import setSixDigitsAfterDot from '@/helpers/setSixDigitsAfterDot'

export default {
  [ROOT]: {
    options: {
      edit: false,
      remove: false,
    },
    infoStructure: [
      {
        icon: MapsIcon.InvertGeoZoneIcon,
        title: <Lang id="installation.card.geoZones" />,
        selector: GEOZONE,
      },
      {
        icon: MapsIcon.InvertProjectIcon,
        title: <Lang id="installation.card.projects" />,
        selector: PROJECT,
      },
      {
        icon: MapsIcon.InvertIntegrationIcon,
        title: <Lang id="installation.card.integrations" />,
        selector: INTEGRATION,
      },
      {
        icon: MapsIcon.InvertObjectIcon,
        title: <Lang id="installation.card.objects" />,
        selector: OBJECT_ELEMENT,
      },
    ],
  },
  [GEOZONE]: {
    options: {
      edit: true,
      remove: true,
    },
    infoStructure: [
      {
        icon: MapsIcon.InvertProjectIcon,
        title: <Lang id="installation.card.projects" />,
        selector: PROJECT,
      },
      {
        icon: MapsIcon.InvertIntegrationIcon,
        title: <Lang id="installation.card.integrations" />,
        selector: INTEGRATION,
      },
      {
        icon: MapsIcon.InvertObjectIcon,
        title: <Lang id="installation.card.objects" />,
        selector: OBJECT_ELEMENT,
      },
    ],
    fields: [
      {
        selector: 'parentGeoZoneName',
      },
      {
        selector: 'point.latitude',
        formatter: setSixDigitsAfterDot,
      },
      {
        selector: 'point.longitude',
        formatter: setSixDigitsAfterDot,
      },
      {
        selector: CREATE_REGION_NAMES.REGION,
      },
      {
        selector: CREATE_REGION_NAMES.CITY,
      },
      {
        selector: 'note',
      },
    ]
  },
  [PROJECT]: {
    options: {
      edit: true,
      remove: true,
    },
    infoStructure: [
      {
        icon: MapsIcon.InvertIntegrationIcon,
        title: <Lang id="installation.card.integrations" />,
        selector: INTEGRATION,
      },
      {
        icon: MapsIcon.InvertObjectIcon,
        title: <Lang id="installation.card.objects" />,
        selector: OBJECT_ELEMENT,
      },
    ],
    fields: [
      {
        selector: 'parentGeoZoneName',
      },
      {
        selector: 'customFields',
      },
      {
        selector: 'note',
      },
    ],
  },
  [INTEGRATION]: {
    options: {
      edit: true,
      remove: true,
    },
    infoStructure: [
      {
        icon: MapsIcon.InvertObjectIcon,
        title: <Lang id="installation.card.objects" />,
        selector: OBJECT_ELEMENT,
      },
    ],
    fields: [
      {
        selector: 'parentName',
      },
      {
        selector: 'integrationType',
      },
    ],
  },
  [OBJECT_ELEMENT]: {
    options: {
      edit: true,
      remove: true,
    },
  },
}
