import {
  put, takeLatest, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_OBJECTS_GROUPS_FILTER_OPTIONS_URL } from '@/constants/apiRoutes'
import { GET_OBJECTS_GROUPS_FILTER_OPTIONS } from '@/store/actions/dictionaries'
import createNotifications from '@/helpers/notification'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'
import {
  errorGetObjectsGroupsFilterOptions,
  successGetObjectsGroupsFilterOptions
} from '@/store/actions/dictionaries/objectsGroupsFilterOptions'
import { TYPE_TO_NAME } from '@/constants/maps'
import { setControlCupboardOriginalDictionary } from '@/store/actions/objectsGroups'
import uniqBy from 'lodash/uniqBy'
import { getControlCupboardOriginalDictionary } from '@/store/selectors/objectsGroups'


function* getDictionary({payload}) {
  try {
    const { filters } = payload
    const controlCupboardOriginalDictionary = yield select(getControlCupboardOriginalDictionary)
    const controlCupboardIds = filters.controlCupboard.map((filter) => controlCupboardOriginalDictionary.filter(item => {
      const value = controlCupboardOriginalDictionary.filter(cupboard => cupboard.id === filter)[0].value
      return item.value === value
    })).flat().map(el => el.id)
    const response = yield request({
      url: GET_OBJECTS_GROUPS_FILTER_OPTIONS_URL({
        params: {
          includeAll: true,
          installationTypes: filters.objectType,
          lampTypes: filters.lightFixtureType,
          locationTypes: filters.pedestrianCrossingSign,
          balanceSheetHolders: filters.networkCompany,
          registerNumbers: filters.registryNumber,
          controlCupboardIds: controlCupboardIds,
          streets: filters.street,
          utilityPoleTypes: filters.utilityPoleType,
          vols: filters.vols,
        }
      }),
      method: 'get',
    })
    if (response && response.data) {
      const streets = response.data.STREET.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedStreets = sortOptionsByTitle(streets)

      const registryNumbers = response.data.REGISTER_NUMBER.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedRegistryNumbers = sortOptionsByTitle(registryNumbers)

      const objectTypes = response.data.INSTALLATION_TYPE.map(item => {
        return {
          title: TYPE_TO_NAME[item.value],
          value: item.value,
        }
      })
      const sortedObjectTypes = sortOptionsByTitle(objectTypes)

      const volses = response.data.VOLS.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedVolses = sortOptionsByTitle(volses)

      const utilityPoleTypes = response.data.UTILITY_POLE_TYPE.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedUtilityPoleTypes = sortOptionsByTitle(utilityPoleTypes)

      const lightFixtureTypes = response.data.LAMP_TYPE.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedLightFixtureTypes = sortOptionsByTitle(lightFixtureTypes)

      const pedestrianCrossingSigns = response.data.LOCATION_TYPE.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedPedestrianCrossingSigns = sortOptionsByTitle(pedestrianCrossingSigns)

      const controlCupboards = response.data.CONTROL_CUPBOARD.map(item => {
        return {
          title: item.value,
          value: item.id,
        }
      })
      const sortedControlCupboards = sortOptionsByTitle(controlCupboards)

      const networkCompanies = response.data.BALANCE_SHEET_HOLDER.map(item => {
        return {
          title: item.value,
          value: item.value,
        }
      })
      const sortedNetworkCompanies = sortOptionsByTitle(networkCompanies)

      const formattedData = {
        street: sortedStreets,
        registryNumber: sortedRegistryNumbers,
        objectType: sortedObjectTypes,
        vols: sortedVolses,
        utilityPoleType: sortedUtilityPoleTypes,
        lightFixtureType: sortedLightFixtureTypes,
        pedestrianCrossingSign: sortedPedestrianCrossingSigns,
        controlCupboard: uniqBy(sortedControlCupboards, 'title'),
        networkCompany: sortedNetworkCompanies,
      }
      yield put(successGetObjectsGroupsFilterOptions(formattedData))
      yield put(setControlCupboardOriginalDictionary(response.data.CONTROL_CUPBOARD))
    }
  } catch (error) {
    console.log('function*getObjectsGroupsFilterOptionsSaga -> error', error)
    yield put(errorGetObjectsGroupsFilterOptions(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные для формы фильтров.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(GET_OBJECTS_GROUPS_FILTER_OPTIONS.REQUEST, getDictionary)
}