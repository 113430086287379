import { handleActions } from 'redux-actions'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
  GET_MOVEABLE_TABLE,
  OBJECT_REFERENCE_MOVEABLE_TABLE,
  SET_LOADING_TABLE,
} from '@/store/actions/objectReference'
import { fieldsMovableProperty } from '@/constants/tablesConfig/objectReference'

const initialState = {
  data: [],
  table: generateDefaultTableState(fieldsMovableProperty),
  loading: false,

}

export const reducer = handleActions(
  {
    [GET_MOVEABLE_TABLE.REQUEST]: (state) => ({
      ...state,
    }),
    [GET_MOVEABLE_TABLE.SUCCESS]: (state, { payload: { data, totalElements } }) => ({
      ...state,
      data,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_MOVEABLE_TABLE.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_LOADING_TABLE]: (state, { payload }) => ({
      ...state,
      loading: payload,
    }),
    ...generateDefaultTableReducer(OBJECT_REFERENCE_MOVEABLE_TABLE),
  },
  initialState,
)

export default reducer
