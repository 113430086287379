import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'


export const GET_REALE_TABLE = createAction('@GET_REALE_TABLE')
export const GET_MOVEABLE_TABLE = createAction('@GET_MOVEABLE_TABLE')
export const GET_FILE = createAction('@/GET_FILE')
export const GET_OBJECT_REFERENCE_INSTALLATION_OBJECT = createAction('@/GET_OBJECT_REFERENCE_INSTALLATION_OBJECT')
export const GET_IMMOVEABLE_DICTINORY = createAction('@/GET_IMMOVEABLE_DICTINORY')

export const GET_IMMOVEABLE_FILTER = '@/GET_IMMOVEABLE_FILTER'
export const SET_IMMOVEABLE_FILTER = '@/SET_IMMOVEABLE_FILTER'



export const GET_OBJECT_REFERENCE_FILE = createAction('@/GET_OBJECT_REFERENCE_FILE')
export const SET_LOADING_TABLE = '@/SET_LOADING_TABLE'


export const OBJECT_REFERENCE_REALE_TABLE = createTableActions('@/OBJECT_REFERENCE_REALE_ABLE')
export const OBJECT_REFERENCE_MOVEABLE_TABLE = createTableActions('@/OBJECT_REFERENCE_MOVEABLE_TABLE')

