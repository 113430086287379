import React, { useState, useEffect, useRef, useCallback } from "react";
import { FormattedMessage as Lang } from 'react-intl'
import useReduxTable from '@/hooks/useReduxTable'
import { OBJECT_REFERENCE_REALE_TABLE, OBJECT_REFERENCE_MOVEABLE_TABLE } from '@/store/actions/objectReference'
import { getTableParametersReale } from '@/store/selectors/objectReferenceReale'
import { getTableParametersMoveable } from "@/store/selectors/objectReferenceMoveable";
import DutyTable from '@/components/blocks/DutyTable'
import CoreIcons from '@/components/icons/core'
import { useHistory } from 'react-router-dom'
import {
  PASPORTIZATION_URL,
} from '@/constants/routes'
import {
  fieldsRealProperty,
  fieldsMovableProperty,
  exportOptions,
} from '@/constants/tablesConfig/objectReference'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import HeaderIcons from '@/components/icons/header'
import Loader from '@/components/blocks/Loader'
import viewTreeIcons from '@/constants/viewTree'









import {
  Main,
  Content,
  TabHeader,
  TabButton,
  Text,
  ContainerTable,
  PageSidebarStyled,
  ContainerIcon,
  ContainerTree,
  HeaderTree,
  IconContainer,
  IconWrapper,
  ContentContainer,
  TypesWrapper,
  ObjectsWrapper,
  TypeContainer,
  ObjectContainer,
  TypeTitle,
  ToggleWrapper,
  ToggleContainer,
  ChildrenCount,

} from './styles'

const TABS_CONFIG = [
  {
   id: 'realProperty',
   name: <Lang id="objectReference.realProperty" />,
  },
  {
   id: 'movableProperty',
   name: <Lang id="objectReference.movableProperty" />,
  }
]

const ObjectReference = ({
  dataReale,
  dataMoveable,
  moveableLoading,
  realeLoading,
  requestObjectReferenceRealeTable,
  requestObjectReferenceMoveableTable,
  requestObjectReferenceFile,
  requestInstallationObject,
  installationObject,
  installationObjectLoading,
  setNode,
  setGoToPassportization,
  immoveableDictinory,
  requestImmoveableTableDictinory,
  immoveableTableFilterValue,
  setImmoveableTableFilter,
}) => {
  const [activeTab, setActiveTab] = useState('realProperty')
  const [selectedRow, setSelectedRow] = useState({})
  const [showDetails, setShowDetails] = useState(false)
  const [installationTypes, setInstallationTypes] = useState([])
  const { push } = useHistory()
  const ref = useRef(null)

  const tablePropsReale = useReduxTable(OBJECT_REFERENCE_REALE_TABLE, getTableParametersReale)
  const tablePropsMoveable = useReduxTable(OBJECT_REFERENCE_MOVEABLE_TABLE, getTableParametersMoveable)

  const tableProps = activeTab === 'realProperty' ? tablePropsReale : tablePropsMoveable

  useEffect(() => {
    setInstallationTypes(installationObject)
  }, [installationObject])

  useEffect(() => {
    if (activeTab === 'realProperty') {
      requestObjectReferenceRealeTable()
    } else {
      requestObjectReferenceMoveableTable()
    }
    requestImmoveableTableDictinory(activeTab)

  },[activeTab, requestObjectReferenceRealeTable, requestObjectReferenceMoveableTable, requestImmoveableTableDictinory])


  const handleSetActiveTab = (tab) => () => {
    setActiveTab(tab)
    setShowDetails(false)
  }

  const requestFile = (payload) => {
    requestObjectReferenceFile({...payload, table: activeTab})
  }

  const handleRow = (row) => {
    requestInstallationObject({
      parentId: row.parentId,
      registerNumber: row.registerNumber,
      row
    })
    setSelectedRow(row)
    setShowDetails(true)
  }

  const handleCloseDetails = () => {
    setShowDetails(false)
  }

  const handleTypeToggle = (type) => {
    const formattedTypes = installationTypes.map(item => {
      return {
        ...item,
        opened: item.name === type.name ? !item.opened : item.opened,
      }
    })
    setInstallationTypes(formattedTypes)
  }

  const handleObjectClick = useCallback((item) => () => {
    setGoToPassportization(true)
    setNode(item)
  }, [setNode, setGoToPassportization])

  const handleFilterColumn = (value, id) => {
    if (id === 'name') {
      setImmoveableTableFilter({
        ...immoveableTableFilterValue,
        [activeTab]: {name: value, year: (immoveableTableFilterValue[activeTab] || {})['year'] || []},
      })
    } else {
      setImmoveableTableFilter({
        ...immoveableTableFilterValue,
        [activeTab]: {name: (immoveableTableFilterValue || {})['name'] || [], year: value}
      })
    }
    requestImmoveableTableDictinory(activeTab)
    if (activeTab === 'realProperty') {
      requestObjectReferenceRealeTable()
    } else {
      requestObjectReferenceMoveableTable()
    }
  }

  return (
    <Main>
      <PageSidebarStyled>
        <ContainerIcon >
          <CoreIcons.TableIcon color={'#1061DF'} onClick={() => push(PASPORTIZATION_URL)}/>
        </ContainerIcon>
      </PageSidebarStyled>
      <Content>
        <TabHeader>
          {TABS_CONFIG.map((element) => {
            return (
              <TabButton
                key={element.id}
                onClick={handleSetActiveTab(element.id)}
                active={activeTab === element.id}
                disabled={false}
              >
                <Text active={activeTab === element.id}>{element.name}</Text>
              </TabButton>
            )
          })}
        </TabHeader>
        <ContainerTable>
          <DutyTable
            {...tableProps}
            fields={activeTab === 'realProperty' ? fieldsRealProperty : fieldsMovableProperty}
            data={activeTab === 'realProperty' ? dataReale : dataMoveable}
            exportOptions={exportOptions}
            onFileSelect={requestFile}
            rowSelector={handleRow}
            selectedRow={selectedRow}
            onUpdateData={activeTab === 'realProperty' ? requestObjectReferenceRealeTable : requestObjectReferenceMoveableTable }
            fieldOptions={immoveableDictinory}
            descriptions={immoveableTableFilterValue[activeTab] || {}}
            handleFilterColumn={handleFilterColumn}
            withFooter
            isDataLoading={realeLoading || moveableLoading}

          />
        </ContainerTable>
        {showDetails &&
          <ContainerTree>
            <HeaderTree>
              <TypeTitle>
                {selectedRow.name}
              </TypeTitle>
              <PortalTooltip
                title={<Lang id="buttons.close" />}
                handleIconClick={() => {}}
                renderChildren={(
                  wrapperRef,
                  onMouseEnterHandler,
                  onMouseLeaveHandler,
                  onClickHandler
                ) => (
                  <IconWrapper
                    onClick={onClickHandler}
                    ref={wrapperRef}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                  >
                    <IconContainer onClick={() => handleCloseDetails()}>
                      <HeaderIcons.MenuCloseIcon />
                    </IconContainer>
                  </IconWrapper>
                )}
              />
            </HeaderTree>
            <ContentContainer>
              { installationObjectLoading
                ? <Loader center />
                : installationTypes.map(type => {
                  return (
                    <>
                      <TypesWrapper key={type.name}>
                        <TypeContainer>
                          <TypeTitle>{type.name}</TypeTitle>
                          <ToggleWrapper>
                            <ChildrenCount>
                              {type.children.length}
                            </ChildrenCount>
                            <ToggleContainer
                              ref={ref}
                              isOpen={type.opened}
                              onClick={() => handleTypeToggle(type)}
                            >
                              <viewTreeIcons.arrow />
                            </ToggleContainer>
                          </ToggleWrapper>
                        </TypeContainer>
                      </TypesWrapper>
                      {!!type.children.length && type.opened &&
                        <ObjectsWrapper>
                          {type.children.map(child => {
                            return (
                              <ObjectContainer key={child.id} onClick={handleObjectClick(child)}>
                                {child.objectIdentifier}
                              </ObjectContainer>
                            )})
                          }
                        </ObjectsWrapper>
                      }
                    </>
                  )
                })
              }
            </ContentContainer>
          </ContainerTree>
        }
      </Content>
    </Main>
  )
}

export default ObjectReference