import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getObjectReferenceReale = createSelector(
  getState,
  (state) => get(state, 'objectReferenceReale', {}),
)

export const getDataReale = createSelector(
  getObjectReferenceReale,
  (settings) => get(settings, 'data'),
)

export const getTableParametersReale = createSelector(
  getObjectReferenceReale,
  (settings) => get(settings, 'table', {}),
)

export const getRealeLoading = createSelector(
  getObjectReferenceReale,
  (settings) => get(settings, 'loading', false),
)

export const getInstallationObject = createSelector(
  getObjectReferenceReale,
  (settings) => get(settings, 'installationObject', []),
)

export const getInstallationObjectLoading = createSelector(
  getObjectReferenceReale,
  (settings) => get(settings, 'installationObjectLoading', false),
)

export const getImmoveableDictinory = createSelector(
  getObjectReferenceReale,
  (settings) => get(settings, 'dictionaryTable', false),
)

export const getImmoveableTableFilter = createSelector(
  getObjectReferenceReale,
  (settings) => get(settings, 'immovebleFilterValue', false),
)

