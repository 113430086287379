import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

import LiveTime from '@/components/icons/livetime'


export const exportOptions = [
  {
    name: 'EXCEL',
    icon: LiveTime.EXELIcon,
  },
]

export const fieldsRealProperty =[
  {
    id: 'number',
    name: '№',
    value: 'ASC',
    customWidth: 60,
  },
  {
    id: 'registerNumber',
    name: <Lang id="tableFields.objectReference.registerNumber" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'name',
    name: <Lang id="tableFields.objectReference.name" />,
    value: 'ASC',
    type: 'string',
    withFilter: true,
  },
  {
    id: 'cadastralNumber',
    name: <Lang id="tableFields.objectReference.cadasterNumber" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'inventoryNumber',
    name: <Lang id="tableFields.objectReference.inventoryNumber" />,
    value: 'ASC',
  },
  {
    id: 'address',
    name: <Lang id="tableFields.objectReference.address" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'year',
    name: <Lang id="tableFields.objectReference.constructionYear" />,
    value: 'ASC',
    withFilter: true,
  },
  {
    id: 'length',
    name: <Lang id="tableFields.objectReference.length" />,
    value: 'ASC',
  },
  {
    id: 'balanceCost',
    name: <Lang id="tableFields.objectReference.priceBalance" />,
    value: 'ASC',
  },
  {
    id: 'residualCost',
    name: <Lang id="tableFields.objectReference.priceResidual" />,
    value: 'ASC',
  },
  {
    id: 'registrationNumber',
    name: <Lang id="tableFields.objectReference.registratioNumber" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'wearPercentage',
    name: <Lang id="tableFields.objectReference.percentageWear" />,
    value: 'ASC',
  },
]

export const fieldsMovableProperty =[
  {
    id: 'number',
    name: '№',
    value: 'ASC',
    customWidth: 60,
  },
  {
    id: 'name',
    name: <Lang id="tableFields.objectReference.name" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'address',
    name: <Lang id="tableFields.objectReference.address" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'year',
    name: <Lang id="tableFields.objectReference.releaseYear" />,
    value: 'ASC',
  },
  {
    id: 'inventoryNumber',
    name: <Lang id="tableFields.objectReference.inventoryNumber" />,
    value: 'ASC',
  },
  {
    id: 'balanceCost',
    name: <Lang id="tableFields.objectReference.priceBalance" />,
    value: 'ASC',
  },
  {
    id: 'residualCost',
    name: <Lang id="tableFields.objectReference.priceResidual" />,
    value: 'ASC',
  },
  {
    id: 'registerNumber',
    name: <Lang id="tableFields.objectReference.registerNumber" />,
    value: 'ASC',
  },
]

export const settingsOptions = [
  {
    name: 'Не просрочен',
    id: 'NOT_EXPIRED',
  },
  {
    name: 'Просрочен',
    id: 'EXPIRED',
  },
]

export const settingsYear = [
  {
    name: '2024',
    id: '2024',
  },
  {
    name: '2023',
    id: '2023',
  },
  {
    name: '2022',
    id: '2022',
  },
  {
    name: '2021',
    id: '2021',
  },
]