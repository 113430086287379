import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'


const PprIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99985 6.19994C9.99985 4.29994 8.79985 2.59994 6.99985 1.99994V5.69994H3.99985V1.99994C2.19985 2.59994 0.999847 4.29994 0.999847 6.19994C0.999847 8.09994 2.19985 9.79994 3.99985 10.3999V21.3999C3.99985 21.7999 4.19985 21.9999 4.49985 21.9999H6.49985C6.79985 21.9999 6.99985 21.7999 6.99985 21.4999V10.4999C8.79985 9.89994 9.99985 8.19994 9.99985 6.19994ZM15.9998 7.99994C15.8998 7.99994 15.9998 7.99994 15.9998 7.99994C12.0998 8.09994 8.99985 11.1999 8.99985 14.9999C8.99985 18.8999 12.0998 21.9999 15.9998 21.9999C19.8998 21.9999 22.9998 18.8999 22.9998 14.9999C22.9998 11.0999 19.8998 7.99994 15.9998 7.99994ZM15.9998 19.9999C13.1998 19.9999 10.9998 17.7999 10.9998 14.9999C10.9998 12.1999 13.1998 9.99994 15.9998 9.99994C18.7998 9.99994 20.9998 12.1999 20.9998 14.9999C20.9998 17.7999 18.7998 19.9999 15.9998 19.9999ZM14.9998 10.9999V15.9999L18.5998 18.1999L19.3998 16.9999L16.4998 15.2999V10.9999H14.9998Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

PprIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PprIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PprIcon)
