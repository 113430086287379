import {
  OK, WARNING, ERROR, INFO, NOT_VERIFIED, VERIFIED, NEW, NOT_EXIST, NOT_INSTALLED, INSTALLED, UNINSTALLED,
} from '@/constants/objectStatuses'

const getStatistic = (
  element,
) => {
  const defaultState = {
    [OK]: 0,
    [WARNING]: 0,
    [INFO]: 0,
    [ERROR]: 0,
  }

  const elementStatistic = element.telemetryStateChildStatistic

  if (elementStatistic && Object.keys(elementStatistic).length) {
    Object.keys(elementStatistic).forEach(item => {
      switch (item) {
        case 'OFF':
          elementStatistic[ERROR] = elementStatistic[ERROR] ? elementStatistic[ERROR] + elementStatistic['OFF'] : elementStatistic['OFF']
          delete elementStatistic['OFF']
          break
        case 'OK':
          elementStatistic[OK] = elementStatistic[OK] ? elementStatistic[OK] + elementStatistic['OK'] : elementStatistic['OK']
          delete elementStatistic['OK']
          break
        case 'NO_CONNECTION':
          elementStatistic[INFO] = elementStatistic[INFO] ? elementStatistic[INFO] + elementStatistic['NO_CONNECTION'] : elementStatistic['NO_CONNECTION']
          delete elementStatistic['NO_CONNECTION']
          break
        default:
          break
      }
    })
  }

  return {
    ...defaultState,
    ...elementStatistic
  }

  // const count = Object.values(statistic).reduce((a, b) => a + b, 0)

  // return {
  //   ...element,
  //   count,
  //   statistic: statistic
  // }
}

export const getZoneStatistic = (
  element,
  objectStateFilters,
  isMonitoring,
) => {
  if (isMonitoring) {
    const count = element.statistic ? Object.values(element.statistic).reduce((acc, el) => acc + el, 0) : 0

    return {
      ...element,
      count,
    }
    
  } else {
    const defaultState = {
      [NOT_VERIFIED]: 0,
      [VERIFIED]: 0,
      [NEW]: 0,
      [NOT_EXIST]: 0,
      [NOT_INSTALLED]: 0,
      [INSTALLED]: 0,
      [UNINSTALLED]: 0,
    }

    let count
    let statistic

    if (!objectStateFilters || objectStateFilters.length === Object.keys(defaultState).length) {
      statistic = {
        ...defaultState,
        ...element.objectStateChildStatistic
      }
      count = element.objectStateChildStatistic ? Object.values(element.objectStateChildStatistic).reduce((a, b) => a + b, 0) : 0
    } else {
      statistic = objectStateFilters.reduce((accumulator, filter) => {
        return ({
          ...accumulator,
          [filter]: element.objectStateChildStatistic[filter] || 0,
        })
      }, {})
      count = Object.values(statistic).reduce((a, b) => a + b, 0)
    }

    return {
      ...element,
      count,
      statistic: {
        ...defaultState, 
        ...statistic
      }
    }
  }
}

export const getRegionsStatistic = (element) => getStatistic(element)

export const getObjectStatistic = (element) => getStatistic(element)

export const getTreeStructure = (county, geoZones) => {
  const treeStructure = county.map((element) => ({
    id: element.id,
    title: element.title,
    active: true,
    type: 'CompanyIcon',
    center: element.location,
    zoom: element.zoom,
    children: geoZones.reduce((accumulator, city) => {
      if (city.countyId === element.id) {
        accumulator.push({
          id: city.id,
          title: city.name,
          zoom: city.zoom,
          type: 'AriaIcon',
          center: city.location,
        })
      }
      return accumulator
    }, []),
  }))
  return treeStructure
}
