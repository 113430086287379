import { GET_FILE } from './index'

export const requestObjectReferenceFile = (payload) => ({
  type: GET_FILE.REQUEST,
  payload,
})

export const successObjectReferenceFile = (payload) => ({
  type: GET_FILE.SUCCESS,
  payload,
})

export const errorObjectReferenceFile = (payload) => ({
  type: GET_FILE.ERROR,
  payload,
})
