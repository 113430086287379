import styled from 'styled-components'
import Sidebar from '@/components/regions/sidebars/Sidebar'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

export const StyledSidebar = styled(Sidebar)`
  z-index: 10;
`

export const UserWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `color: ${theme.colors.colors.redisign.hightLighter};`
    }
    return `color: ${theme.colors.colors.default};`
  }}
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.9rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 100px;
    background: no-repeat center / cover url(${({ theme }) => theme.images.signIn});
  }
`

export const UserImage = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-top: 57px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`

export const UserName = styled.div`
  margin-top: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
`
export const UserRole = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: center;

  ${({ spaced }) => spaced && `
    margin-bottom: 20px;
  `}
  ${({ dark, theme }) => dark && `
    color: ${theme.colors.colors.disabled};
  `}
`

export const NumberWrapper = styled.div`
  padding: 50px 15px;
  margin-top: auto;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1.4rem;
  line-height: 1.6rem;
`

export const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 5px;
`

export const VersionWrapper = styled.div`
  margin-top: 20px;
`
export const NumberVersion = styled.div`
  font-weight: 500;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.colors.default};
`

export const ChengeLogWrapper = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.colors.default};
  text-decoration: underline;
  cursor: pointer;
`