export const sortObjectIdentifier = (items) => {
  return items.filter(item => item.objectIdentifier).sort((a, b) => {
    const titleA = a.objectIdentifier.toUpperCase()
    const titleB = b.objectIdentifier.toUpperCase()
    if (titleA < titleB) {
      return -1
    }

    if (titleA > titleB) {
      return 1
    }

    return 0
  })
}