import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'
import Popup from '@/components/blocks/Popup'
import { PopupContainer } from '@/components/blocks/Popup/styles'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: ${({ theme }) => theme.sizes.headerHeight}px;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        border-bottom: 1px solid ${theme.colors.borders.redisign.default};
        background: ${theme.colors.backgrounds.redisign.topDownGradient};
      `
    }
    return `
      border-bottom: 1px solid ${theme.colors.borders.default};
      background: ${theme.colors.backgrounds.header};
    `
  }}
  color: ${({ theme }) => theme.colors.colors.default};
  z-index: 10;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  > a > svg {
    width: 59px;
    max-height: 38px;
    &:first-child {
      margin-left: 10px;
    }
  }
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`
export const StyledLink = styled(Link)`
  text-decoration: none;
`

export const LogoContainer = styled.div`
  margin-left: 10px;
  display: flex;
`
export const TitleLogo = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.colors.default};
`

export const ControlsContainer = styled.div`
  display: flex;
  height: 100%;
  ${PopupContainer} {
    padding: 10px;
  }
`
export const StyledPopup = styled(Popup)`
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -10;
  transition: 0.4s;
  ${({ opened }) => opened && `
    top: calc(100% +15px);
  `}
`

export const Title = styled.h1`
font-size: 24px;
color: #1061DF;
padding-left: 10px;
`
export const WidgetHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 5px 0 5px;
  position: relative;

  & > svg {
    cursor: pointer;
  }
`

export const UsersContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  ${({ theme, onlineLength }) => onlineLength ? `color: #90C737;` : `${theme.colors.colors.default}}`}

`

export const UsersCount = styled.div`
  height: 28px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-left: 7px;
  border-radius: 10px;
  ${({ isOpen }) => isOpen ? `background-color: #000000;` : 'background-color: #1160DF'}
`

export const ZeroUsers = styled.div`
  height: 28px;
  color: #878687;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-left: 7px;
  border-radius: 10px;
  background-color: #dddddd
`

export const ToggleContainer = styled.div`
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
  svg {
    transform-origin: center;
    transform: rotate(180deg);
  }
  ${({ isOpen }) => isOpen && `
    svg {
      transform-origin: center;
      transform: rotate(0deg);
    }
  `}
`

export const IconWrapper = styled.div`
  padding-top: 5px;
  margin-right: 5px;
  svg {
    width: 18px;
    height: 18px;
  }
`
