import passportSHUO from './Паспорт ШУНО Unilight.pdf'
import passportLampModule from './Паспорт Модуль Unilight NEMA.pdf'
import passportGaleonS from './Паспорт на Галеон серии S.pdf'
import passportGaleonL from './Паспорт на Галеон серии L.pdf'
import passportGalad from './Паспорт_светильник GALAD_Волна_LED-150-ШБ.pdf'
import passportGaleonSLed from './Паспорт на Галеон серии S LED.pdf'



import { LIGHT_FIXTURE_GALEON_S, LIGHT_FIXTURE_GALEON_L, CONTROL_CUPBOARD, LAMP_MODULE, LIGHT_FIXTURE_GALAD, LIGHT_FIXTURE_GALEON_S_LED } from '@/constants/instalationPassport/types'

export default  {
  [CONTROL_CUPBOARD]: passportSHUO,
  [LAMP_MODULE]: passportLampModule,
  [LIGHT_FIXTURE_GALEON_S]: passportGaleonS,
  [LIGHT_FIXTURE_GALEON_L]: passportGaleonL,
  [LIGHT_FIXTURE_GALAD]: passportGalad,
  [LIGHT_FIXTURE_GALEON_S_LED]: passportGaleonSLed,
}