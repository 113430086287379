import React, { useCallback, useMemo } from 'react'
import { FormattedMessage as Lang, injectIntl } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash'
import widgetsByTypes from '@/constants/telemetries'
import { MARKS_COLORS } from '@/constants/styles/mapsMarks'
import { OK, INFO } from '@/constants/objectStatuses'
import {
  IndicatorsBlock,
  IndicatorsWrapper,
  StateIndicatorStyled,
  StatusIndicatorStyled,
  WorkTimeIndicatorStyled,
  TrashIndicatorStyled,
  SmallWidgetIndicatorStyled,
  LightIndicatorStyled,
  ConnectionStatusIndicatorStyled,
  ControllerStatusIndicatorStyled, MultiDimmingIndicatorStyled,
} from './styles'
import { LoaderContainer } from '@/components/regions/sidebars/PassportSidebar/styles';
import Loader from '@/components/blocks/Loader';
import { GROUP } from '@/constants/objectTypes';
import EmptyDataState from 'components/blocks/EmptyDataState'

const ObjectState = ({
  type,
  installationType,
  integrationType,
  status,
  telemetry,
  settings,
  refreshIntegration,
  updateWidgetSettings,
  intl,
  objectTelemetryStatus,
  card,
  ...props
}) => {
  const widgets = useMemo(() => {
    if (integrationType && installationType) {
      return widgetsByTypes[integrationType][installationType]
    }
    return []
  }, [integrationType, installationType])
  const handleUpdateWidgetSettings = useCallback((id) => (values) => {
    updateWidgetSettings(id, values)
  }, [updateWidgetSettings])

  const parseStringToObject = (str)=> {
    if (!str) {
      return
    }
    const cleanStr = str.replace(/[[\]]/g, '');
    const pairs = cleanStr.split(',');
    
    const result = pairs.reduce((acc, pair) => {
      const [key, value] = pair.trim().split(':').map(item => item.trim());
      return { ...acc, [key]: value };
    }, {});
  
    return result
  };

  const getWidgetByType = useCallback(
    (indicator) => {
      const key = indicator.id || indicator.type
      const settingsId = `${indicator.id}-${props.id}`
      switch (indicator.type) {
        case 'status': {
          return (
            <StatusIndicatorStyled
              key={key}
              {...indicator}
              refreshIntegration={refreshIntegration}
              color={MARKS_COLORS[telemetry.status || status]}
              status={telemetry.status || status}
              installationType={installationType}
              modeStatus={telemetry.turnedOn}
              timestamp={indicator.timeSelector || props.dateModified ? telemetry[indicator.timeSelector] || props.dateModified : 0}
              {...props}
            />
          )
        }
        case 'controllerStatus': {
          return (
            <ControllerStatusIndicatorStyled
              key={key}
              {...indicator}
              refreshIntegration={refreshIntegration}
              firstTitle={indicator.firstTitle}
              secondTitle={indicator.secondTitle}
              firstStatusSelector={telemetry[indicator.firstStatusSelector]}
              secondStatusSelector={telemetry[indicator.secondStatusSelector]}
              installationType={installationType}
              timestamp={indicator.timeSelector || props.dateModified ? telemetry[indicator.timeSelector] || props.dateModified : 0}
              {...props}
            />
          )
        }
        case 'trash': {
          return (
            <TrashIndicatorStyled
              key={key}
              telemetry={telemetry}
              {...indicator}
            />
          )
        }
        case 'bigLight': {
          return (
            <LightIndicatorStyled
              key={key}
              value={telemetry.poleAngleDrift || {}}
              valueHeight={telemetry.lanternHeight || 0}
              {...indicator}
            />
          )
        }
        case 'smallWidget': {
          return (
            <SmallWidgetIndicatorStyled
              key={key}
              value={
                (indicator.valueSelector ? telemetry[indicator.valueSelector] : 0)
              }
              {...indicator}
            />
          )
        }
        case 'workTime': {
          return (
            <WorkTimeIndicatorStyled
              key={key}
              {...indicator}
              intl={intl}
              color={telemetry.turnedOn
                ? MARKS_COLORS[OK]
                : MARKS_COLORS[INFO]}
              online={telemetry.turnedOn}
              status={status === 'NO_PROBLEM'}
              workTime={indicator.workTimeSelector ? telemetry[indicator.workTimeSelector] : 0}
            />
          )
        }
        case 'connectionStatus': {
          return (
            <ConnectionStatusIndicatorStyled
              key={key}
              {...indicator}
              color={telemetry.isConnected
                ? MARKS_COLORS[OK]
                : MARKS_COLORS[INFO]}
              online={telemetry.isConnected}
              status={status === 'NO_PROBLEM'}
            />
          )
        }
        case 'multiDimming': {
          return (
              <MultiDimmingIndicatorStyled
                  key={key}
                  telemetry={telemetry}
                  onUpdateSettings={handleUpdateWidgetSettings(settingsId)}
                  {...indicator}
              />
          )
        }
        default: {
          return (
            <StateIndicatorStyled
              key={key}
              {...indicator}
              color={MARKS_COLORS[status]}
              status={telemetry.state}
              settings={card && parseStringToObject(card.find((el) => el.fieldName === indicator.id)?.value)}
              onUpdateSettings={handleUpdateWidgetSettings(settingsId)}
              value={
                (indicator.valueSelector ? telemetry[indicator.valueSelector] : 0)
                || indicator.mockValue
              }
              data={indicator.data && indicator.data.reduce((array, row, index) => {
                const selectedValue = telemetry[row.selector]
                const totalSelector = `${row.selector.slice(0,-1)}0`
                if (index === indicator.data.length - 1 && telemetry[totalSelector]) {
                  array.unshift({
                    title: '∑  ',
                    value: telemetry[totalSelector].toFixed(3) / 1000,
                    type: row.type
                  })
                }
                const value = selectedValue ? selectedValue.toFixed(3) : 0
                if (selectedValue !== null) {
                  return [
                    ...array,
                    {
                      title: row.title,
                      value: row.title.startsWith('T') ? value / 1000 : value,
                      type: row.type,
                    },
                  ]
                }
                return array
              }, [])}
            />
          )
        }
      }
    },
    [handleUpdateWidgetSettings, props, status, telemetry, refreshIntegration, installationType, intl, card],
  )
  const renderContent = () => {
    if (objectTelemetryStatus === 'PENDING') {
      return (
      <LoaderContainer>
        <Loader center/>
      </LoaderContainer>
      )
    }
    if (!installationType || installationType === GROUP) {
      return <></>
    }
    return (
        Object.keys(telemetry).length ?
          <IndicatorsBlock>
            <IndicatorsWrapper>
              {(widgets || []).map(getWidgetByType)}
            </IndicatorsWrapper>
          </IndicatorsBlock> 
          :
          <EmptyDataState title={<Lang id="mapsPage.emptyTelemetry"/> }/>
    )}

  return renderContent()
}

ObjectState.propTypes = {
  id: pt.oneOfType([pt.string, pt.number]),
  type: pt.string,
  status: pt.string,
  system: pt.string,
  refreshIntegration: pt.func,
  telemetry: pt.shape({
    timestamp: pt.string,
    state: pt.string,
  }),
  settings: pt.shape({
    [pt.string]: pt.object,
  }),
  updateWidgetSettings: pt.func.isRequired,
}
ObjectState.defaultProps = {
  id: null,
  type: '',
  status: '',
  system: '',
  telemetry: {},
  settings: {},
  refreshIntegration: noop,
}

export default injectIntl(ObjectState)
