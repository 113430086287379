import {
  takeLatest, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import qs from 'query-string'
import moment from 'moment/moment'
import {
  GET_FILE,
} from '@/store/actions/objectReference'
import {
  GET_REALE_FILE_URL,
  GET_MOVEABLE_FILE_URL
} from '@/constants/apiRoutes'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import {
  getTableParametersReale,
  getImmoveableTableFilter,
} from '@/store/selectors/objectReferenceReale'
import {
  getTableParametersMoveable,
} from '@/store/selectors/objectReferenceMoveable'

function* getFileObjectReferenceSaga({payload}) {
  const toast = createNotifications()
  try {
    const {search} = window.location
    const projectId = qs.parse(search)['projectId']
    const projectName = qs.parse(search)['projectName']
    const tableParametersReale = yield select(getTableParametersReale)
    const tableParametersMoveable = yield select(getTableParametersMoveable)
    const filterValue = yield select(getImmoveableTableFilter)
    const tableParameters = payload.table === 'realProperty' ? tableParametersReale : tableParametersMoveable 
    const url = payload.table === 'realProperty' ? GET_REALE_FILE_URL : GET_MOVEABLE_FILE_URL
    const response = yield request({
      url: url({
        params: {
          excludeColumns: payload.allColumns.filter(x => !tableParameters.activeFields.includes(x)),
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          parentId: projectId,
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          searchText: tableParameters.requestQuery || '',
          names: filterValue[payload.table ] && filterValue[payload.table ]['name'].map((item) => item.value),
          years: filterValue[payload.table ] && filterValue[payload.table ]['year'].map((item) => item.value),
        },
      }),
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `Справочник объектов проекта '${projectName} на дату формирования документа ${moment(new Date()).format('DD.MM.YYYY')}.xlsx`,
    })
  } catch (error) {
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить файл. Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_FILE.REQUEST, getFileObjectReferenceSaga)
}
