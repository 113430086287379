import { GET_REALE_TABLE, SET_LOADING_TABLE, GET_MOVEABLE_TABLE, GET_IMMOVEABLE_DICTINORY, GET_IMMOVEABLE_FILTER, SET_IMMOVEABLE_FILTER } from './index'

export const requestObjectReferenceRealeTable = (payload) => ({
  type: GET_REALE_TABLE.REQUEST,
  payload,
})

export const successObjectReferenceRealeTable = (payload) => ({
  type: GET_REALE_TABLE.SUCCESS,
  payload,
})

export const errorObjectReferenceRealeTable = (payload) => ({
  type: GET_REALE_TABLE.ERROR,
  payload,
})

export const requestObjectReferenceMoveableTable = (payload) => ({
  type: GET_MOVEABLE_TABLE.REQUEST,
  payload,
})

export const successObjectReferenceMoveableTable = (payload) => ({
  type: GET_MOVEABLE_TABLE.SUCCESS,
  payload,
})

export const errorObjectReferenceMoveableTable = (payload) => ({
  type: GET_MOVEABLE_TABLE.ERROR,
  payload,
})

export const setLoadingTable = (payload) => ({
  type: SET_LOADING_TABLE,
  payload,
})

export const successImmoveableTableDictinory = (payload) => ({
  type: GET_IMMOVEABLE_DICTINORY.SUCCESS,
  payload,
})

export const requestImmoveableTableDictinory = (payload) => ({
  type: GET_IMMOVEABLE_DICTINORY.REQUEST,
  payload,
})

export const getImmoveableTableFilter = (payload) => ({
  type: GET_IMMOVEABLE_FILTER,
  payload,
})

export const setImmoveableTableFilter = (payload) => ({
  type: SET_IMMOVEABLE_FILTER,
  payload,
})