import { GET_ACTION_LOG_FILE } from './index'

export const requestActivitiLogFile = (payload) => ({
  type: GET_ACTION_LOG_FILE.REQUEST,
  payload,
})

export const successActivitiLogFile = (payload) => ({
  type: GET_ACTION_LOG_FILE.SUCCESS,
  payload,
})

export const errorSummaryActivitiLogFile = (payload) => ({
  type: GET_ACTION_LOG_FILE.ERROR,
  payload,
})
