import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getInstallation = createSelector(
  getState,
  (state) => get(state, 'installation', {}),
)

export const getParams = createSelector(
  getInstallation,
  (state) => get(state, 'params', {}),
)

export const getTree = createSelector(
  getInstallation,
  (installation) => get(installation, 'tree'),
)

export const getNode = createSelector(
  getInstallation,
  (installation) => get(installation, 'node'),
)

export const getPins = createSelector(
  getInstallation,
  (installation) => get(installation, 'pins'),
)

export const getChild = createSelector(
  getInstallation,
  (installation) => get(installation, 'child'),
)

export const getRoot = createSelector(
  getInstallation,
  (installation) => get(installation, 'root'),
)

export const getStreamUrl = createSelector(
  getInstallation,
  (installation) => get(installation, 'streamUrl'),
)

export const getTreeData = createSelector(
  getInstallation,
  (installation) => get(installation, 'treeData'),
)

export const getRootStatus = createSelector(
  getInstallation,
  (installation) => get(installation, 'rootStatus'),
)

export const getCard = createSelector(
  getInstallation,
  (installation) => get(installation, 'card'),
)

export const getObjectTelemetry = createSelector(
  getInstallation,
  (installation) => get(installation, 'objectTelemetry'),
)

export const getObjectTelemetryStatus = createSelector(
  getInstallation,
  (installation) => get(installation, 'objectTelemetryStatus'),
)

export const getNodeStatistic = createSelector(
  getInstallation,
  (installation) => get(installation, 'nodeStatistic'),
)

export const getIntegrationTypes = createSelector(
  getInstallation,
  (installation) => get(installation, 'integrationTypes'),
)

export const getObjectTypes = createSelector(
  getInstallation,
  (installation) => get(installation, 'objectTypes'),
)

export const getParentElements = createSelector(
  getInstallation,
  (installation) => get(installation, 'parentElements'),
)

export const getElementsInfo = createSelector(
  getInstallation,
  (installation) => get(installation, 'elementsInfo'),
)

export const getDisplayElements = createSelector(
  getInstallation,
  (installation) => get(installation, 'displayElements'),
)

export const getValidationScheme = createSelector(
  getInstallation,
  (installation) => get(installation, 'validationScheme'),
)

export const getMapCenter = createSelector(
  getInstallation,
  (installation) => get(installation, 'mapCenter'),
)

export const getMapZoom = createSelector(
  getInstallation,
  (installation) => get(installation, 'mapZoom'),
)

export const getIntegrationByType = createSelector(
  getInstallation,
  (installation) => get(installation, 'integration'),
)

export const getObjectTypesRequestStatus = createSelector(
  getInstallation,
  (installation) => get(installation, 'objectTypesRequestStatus'),
)

export const getIntegrationRequestStatus = createSelector(
  getInstallation,
  (installation) => get(installation, 'integrationRequestStatus'),
)

export const getSelectedElementParams = createSelector(
  getInstallation,
  (installation) => get(installation, 'selectedElementParams'),
)

export const getTreeHasChange = createSelector(
  getInstallation,
  (installation) => get(installation, 'treeHasChange'),
)

export const getDisplayElementAndTelemetryRequestStatus = createSelector(
  getInstallation,
  (installation) => get(installation, 'displayElementAndTelemetryRequestStatus'),
)

export const getObjectConnectedIdentifiers = createSelector(
    getInstallation,
    (installation) => get(installation, 'objectConnectedIdentifiers'),
)

export const getConnectedLinesCoordinates = createSelector(
    getInstallation,
    (installation) => get(installation, 'connectedLinesCoordinates'),
)

export const getConnectedLinesCoordinatesRequestStatus = createSelector(
    getInstallation,
    (installation) => get(installation, 'connectedLinesCoordinatesRequestStatus'),
)
